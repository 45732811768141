// @ts-nocheck
import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import flow from 'lodash/flow'
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk'
import { v4 as uuidv4 } from 'uuid'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import tokens from 'constants/enums/tokens'
import ldFlagNames from 'constants/enums/ldFlagNames'
import MockLDProvider from './MockLDProvider'
import App from './App'
import {
  currentEnvironment,
  PARTNER_HASH_LENGTH,
  getApiHost,
  defaultApiHost
} from './properties/properties'
import RedirectSubdomains from './RedirectSubdomains'
import { BackgroundImageOptions } from '../src/types'
import featureFlags from 'constants/enums/featureFlags'
import dataDogKeys from 'constants/enums/dataDogKeys'

const AppWrapper = ({ flags }) => {
  const dataDogEnabled = flags[featureFlags.dataDogEnabled]
  const apiHostUrl = getApiHost(currentEnvironment, flags)
  const apiHostOrigin = new URL(apiHostUrl).origin
  const datadogEnv = currentEnvironment === 'production' ? 'prod' : currentEnvironment
  useEffect(() => {
    if (dataDogEnabled) {
      datadogLogs.init({
        clientToken: dataDogKeys.DATADOG_LOGS_CLIENT_TOKEN,
        site: 'datadoghq.com',
        sessionSampleRate: 100,
        forwardErrorsToLogs: false,
        forwardConsoleLogs: window.location?.hostname === 'localhost' ? [] : ['error']
      })

      datadogRum.init({
        applicationId: dataDogKeys.DATADOG_RUM_APPLICATION_ID,
        clientToken: dataDogKeys.DATADOG_RUM_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'prospect',
        env: datadogEnv,
        sessionSampleRate: 100,
        // session replay is not enabled on Mosaic subscription
        sessionReplaySampleRate: 0,
        // enable tracing for service url based on environment
        allowedTracingUrls: [apiHostOrigin],
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      })
    }
  }, [dataDogEnabled, apiHostOrigin])

  return (
    <div
      id="app-wrapper"
      data-env={currentEnvironment}
      data-api-base-url={apiHostUrl}
    >
      <RedirectSubdomains />
      <App />
    </div>
  )
}

const { CI, NODE_ENV } = process.env
const withTestSafeLDProvider =
  CI || NODE_ENV === 'test' ? MockLDProvider : withLDProvider

const getPartnerHash = () => {
  const pathArray = window.location.pathname.split('/')
  const partnerHash =
    pathArray[2] && pathArray[2].length === PARTNER_HASH_LENGTH
      ? pathArray[2]
      : null
  return partnerHash
}

export default flow(
  withLDConsumer(),
  withTestSafeLDProvider({
    clientSideID: tokens.launchDarkly,
    context: {
      kind: 'user',
      key: `guest-${uuidv4()}`,
      partnerHash: getPartnerHash()
    },
    flags: {
      [ldFlagNames.maintenanceMode]: false,
      [ldFlagNames.termsOfService]: false,
      [ldFlagNames.salesforceUrl]: defaultApiHost,
      [ldFlagNames.googlePlacesToken]: {},
      [ldFlagNames.redirectSubdomains]: {},
      [ldFlagNames.prospectApplicationBackgroundImage]:
        BackgroundImageOptions.original,
      [ldFlagNames.servicesOfferedForReferralCode]: {},
      [ldFlagNames.prospectFullStory]: false,
      [ldFlagNames.prospectDataDog]: false
    }
  }),
  withRouter
)(AppWrapper)
